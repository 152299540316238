/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-22 13:52:49
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2025-03-17 15:52:07
 * @FilePath: \qctsw-vue\packages\app\src\plugins\error.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default defineNuxtPlugin((nuxtApp) => {
  //
  nuxtApp.vueApp.config.errorHandler = (error) => {
    console.error('🚀 ~ nuxtApp.hook ~ error:', error)
    // window.location.reload()
  }
})
