import { default as _91_46_46_46slug_93GOaf4lzrAyMeta } from "D:/word/qctsw-vue/packages/app/src/pages/[...slug].vue?macro=true";
import { default as _91collectiveId_93_32copyz6lDJQj2v1Meta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/collective/[collectiveId] copy.vue?macro=true";
import { default as _91collectiveId_93lPNzxHgu8ZMeta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/collective/[collectiveId].vue?macro=true";
import { default as _91id_93hAxQQ9t5hrMeta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/detail/[id].vue?macro=true";
import { default as indexW0unMG9dPgMeta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/index.vue?macro=true";
import { default as listnofiTt8TuOMeta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/list.vue?macro=true";
import { default as searchzNPQXCUH2CMeta } from "D:/word/qctsw-vue/packages/app/src/pages/complaint/search.vue?macro=true";
import { default as forgetNKsU0WoQ3cMeta } from "D:/word/qctsw-vue/packages/app/src/pages/forget.vue?macro=true";
import { default as _91id_931DWmXmtj8DMeta } from "D:/word/qctsw-vue/packages/app/src/pages/help/[id].vue?macro=true";
import { default as indexbIVeJbrr34Meta } from "D:/word/qctsw-vue/packages/app/src/pages/index/index.vue?macro=true";
import { default as red_45blackbJJs6jCqMbMeta } from "D:/word/qctsw-vue/packages/app/src/pages/list/red-black.vue?macro=true";
import { default as _91id_93WsOV4MUfuLMeta } from "D:/word/qctsw-vue/packages/app/src/pages/news/author/[id].vue?macro=true";
import { default as classificationNpgmlagw74Meta } from "D:/word/qctsw-vue/packages/app/src/pages/news/classification.vue?macro=true";
import { default as _91id_93A8HlNCwwaMMeta } from "D:/word/qctsw-vue/packages/app/src/pages/news/detail/[id].vue?macro=true";
import { default as indexLf0xYAFQMxMeta } from "D:/word/qctsw-vue/packages/app/src/pages/news/index.vue?macro=true";
import { default as _91id_93219tibwMMwMeta } from "D:/word/qctsw-vue/packages/app/src/pages/news/media/[id].vue?macro=true";
import { default as indexkB71G78DhGMeta } from "D:/word/qctsw-vue/packages/app/src/pages/news/media/index.vue?macro=true";
import { default as indexKTOgVglw8mMeta } from "D:/word/qctsw-vue/packages/app/src/pages/publish/index.vue?macro=true";
import { default as registergNiSicKKBaMeta } from "D:/word/qctsw-vue/packages/app/src/pages/register.vue?macro=true";
import { default as _91seriesId_93ABPMH7GJjlMeta } from "D:/word/qctsw-vue/packages/app/src/pages/reputation/[seriesId].vue?macro=true";
import { default as index7QOSw25r7BMeta } from "D:/word/qctsw-vue/packages/app/src/pages/reputation/index.vue?macro=true";
import { default as indexHNfPpQBJG4Meta } from "D:/word/qctsw-vue/packages/app/src/pages/search/index.vue?macro=true";
import { default as _91id_93m7leOWvVlBMeta } from "D:/word/qctsw-vue/packages/app/src/pages/topic/[id].vue?macro=true";
import { default as addressManageXHH3PcsOZiMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/addressManage.vue?macro=true";
import { default as bindAccountpUJoR2K45jMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/bindAccount.vue?macro=true";
import { default as editkmmTazGtgoMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/car/edit.vue?macro=true";
import { default as indexuWuSR6O5u8Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/car/index.vue?macro=true";
import { default as collectionqsEgKVSpgDMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/collection.vue?macro=true";
import { default as commentE8VbO2BdaGMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/comment.vue?macro=true";
import { default as _91id_93d7kin93XQdMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/complaint/[id].vue?macro=true";
import { default as index6HuF1hgdt2Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/complaint/index.vue?macro=true";
import { default as dynamic5o6jm6v4kxMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/dynamic.vue?macro=true";
import { default as exchange_45logwimRyy2R41Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/exchange-log.vue?macro=true";
import { default as integration_45logbbUM1YjJR6Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/integration-log.vue?macro=true";
import { default as integrationBt5dFt1aWuMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/integration.vue?macro=true";
import { default as modifyPassword7wCkuJn0SeMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/modifyPassword.vue?macro=true";
import { default as modifyPhone2UtuTpfqi6Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/modifyPhone.vue?macro=true";
import { default as notificatione0p0EZ4cXOMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/notification.vue?macro=true";
import { default as store7Q0k0lsgn9Meta } from "D:/word/qctsw-vue/packages/app/src/pages/user/store.vue?macro=true";
import { default as userInfoQUjwuvU0zlMeta } from "D:/word/qctsw-vue/packages/app/src/pages/user/userInfo.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93GOaf4lzrAyMeta?.name ?? "slug",
    path: _91_46_46_46slug_93GOaf4lzrAyMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93GOaf4lzrAyMeta || {},
    alias: _91_46_46_46slug_93GOaf4lzrAyMeta?.alias || [],
    redirect: _91_46_46_46slug_93GOaf4lzrAyMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91collectiveId_93_32copyz6lDJQj2v1Meta?.name ?? "complaint-collective-collectiveId copy",
    path: _91collectiveId_93_32copyz6lDJQj2v1Meta?.path ?? "/complaint/collective/:collectiveId()%20copy",
    meta: _91collectiveId_93_32copyz6lDJQj2v1Meta || {},
    alias: _91collectiveId_93_32copyz6lDJQj2v1Meta?.alias || [],
    redirect: _91collectiveId_93_32copyz6lDJQj2v1Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/collective/[collectiveId] copy.vue").then(m => m.default || m)
  },
  {
    name: _91collectiveId_93lPNzxHgu8ZMeta?.name ?? "complaint-collective-collectiveId",
    path: _91collectiveId_93lPNzxHgu8ZMeta?.path ?? "/complaint/collective/:collectiveId()",
    meta: _91collectiveId_93lPNzxHgu8ZMeta || {},
    alias: _91collectiveId_93lPNzxHgu8ZMeta?.alias || [],
    redirect: _91collectiveId_93lPNzxHgu8ZMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/collective/[collectiveId].vue").then(m => m.default || m)
  },
  {
    name: _91id_93hAxQQ9t5hrMeta?.name ?? "complaint-detail-id",
    path: _91id_93hAxQQ9t5hrMeta?.path ?? "/complaint/detail/:id()",
    meta: _91id_93hAxQQ9t5hrMeta || {},
    alias: _91id_93hAxQQ9t5hrMeta?.alias || [],
    redirect: _91id_93hAxQQ9t5hrMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexW0unMG9dPgMeta?.name ?? "complaint",
    path: indexW0unMG9dPgMeta?.path ?? "/complaint",
    meta: indexW0unMG9dPgMeta || {},
    alias: indexW0unMG9dPgMeta?.alias || [],
    redirect: indexW0unMG9dPgMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/index.vue").then(m => m.default || m)
  },
  {
    name: listnofiTt8TuOMeta?.name ?? "complaint-list",
    path: listnofiTt8TuOMeta?.path ?? "/complaint/list",
    meta: listnofiTt8TuOMeta || {},
    alias: listnofiTt8TuOMeta?.alias || [],
    redirect: listnofiTt8TuOMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/list.vue").then(m => m.default || m)
  },
  {
    name: searchzNPQXCUH2CMeta?.name ?? "complaint-search",
    path: searchzNPQXCUH2CMeta?.path ?? "/complaint/search",
    meta: searchzNPQXCUH2CMeta || {},
    alias: searchzNPQXCUH2CMeta?.alias || [],
    redirect: searchzNPQXCUH2CMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/complaint/search.vue").then(m => m.default || m)
  },
  {
    name: forgetNKsU0WoQ3cMeta?.name ?? "forget",
    path: forgetNKsU0WoQ3cMeta?.path ?? "/forget",
    meta: forgetNKsU0WoQ3cMeta || {},
    alias: forgetNKsU0WoQ3cMeta?.alias || [],
    redirect: forgetNKsU0WoQ3cMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/forget.vue").then(m => m.default || m)
  },
  {
    name: _91id_931DWmXmtj8DMeta?.name ?? "help-id",
    path: _91id_931DWmXmtj8DMeta?.path ?? "/help/:id()",
    meta: _91id_931DWmXmtj8DMeta || {},
    alias: _91id_931DWmXmtj8DMeta?.alias || [],
    redirect: _91id_931DWmXmtj8DMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/help/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbIVeJbrr34Meta?.name ?? "index",
    path: indexbIVeJbrr34Meta?.path ?? "/",
    meta: indexbIVeJbrr34Meta || {},
    alias: indexbIVeJbrr34Meta?.alias || [],
    redirect: indexbIVeJbrr34Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: red_45blackbJJs6jCqMbMeta?.name ?? "list-red-black",
    path: red_45blackbJJs6jCqMbMeta?.path ?? "/list/red-black",
    meta: red_45blackbJJs6jCqMbMeta || {},
    alias: red_45blackbJJs6jCqMbMeta?.alias || [],
    redirect: red_45blackbJJs6jCqMbMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/list/red-black.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WsOV4MUfuLMeta?.name ?? "news-author-id",
    path: _91id_93WsOV4MUfuLMeta?.path ?? "/news/author/:id()",
    meta: _91id_93WsOV4MUfuLMeta || {},
    alias: _91id_93WsOV4MUfuLMeta?.alias || [],
    redirect: _91id_93WsOV4MUfuLMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/author/[id].vue").then(m => m.default || m)
  },
  {
    name: classificationNpgmlagw74Meta?.name ?? "news-classification",
    path: classificationNpgmlagw74Meta?.path ?? "/news/classification",
    meta: classificationNpgmlagw74Meta || {},
    alias: classificationNpgmlagw74Meta?.alias || [],
    redirect: classificationNpgmlagw74Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/classification.vue").then(m => m.default || m)
  },
  {
    name: _91id_93A8HlNCwwaMMeta?.name ?? "news-detail-id",
    path: _91id_93A8HlNCwwaMMeta?.path ?? "/news/detail/:id()",
    meta: _91id_93A8HlNCwwaMMeta || {},
    alias: _91id_93A8HlNCwwaMMeta?.alias || [],
    redirect: _91id_93A8HlNCwwaMMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLf0xYAFQMxMeta?.name ?? "news",
    path: indexLf0xYAFQMxMeta?.path ?? "/news",
    meta: indexLf0xYAFQMxMeta || {},
    alias: indexLf0xYAFQMxMeta?.alias || [],
    redirect: indexLf0xYAFQMxMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93219tibwMMwMeta?.name ?? "news-media-id",
    path: _91id_93219tibwMMwMeta?.path ?? "/news/media/:id()",
    meta: _91id_93219tibwMMwMeta || {},
    alias: _91id_93219tibwMMwMeta?.alias || [],
    redirect: _91id_93219tibwMMwMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/media/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkB71G78DhGMeta?.name ?? "news-media",
    path: indexkB71G78DhGMeta?.path ?? "/news/media",
    meta: indexkB71G78DhGMeta || {},
    alias: indexkB71G78DhGMeta?.alias || [],
    redirect: indexkB71G78DhGMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/news/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTOgVglw8mMeta?.name ?? "publish",
    path: indexKTOgVglw8mMeta?.path ?? "/publish",
    meta: indexKTOgVglw8mMeta || {},
    alias: indexKTOgVglw8mMeta?.alias || [],
    redirect: indexKTOgVglw8mMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/publish/index.vue").then(m => m.default || m)
  },
  {
    name: registergNiSicKKBaMeta?.name ?? "register",
    path: registergNiSicKKBaMeta?.path ?? "/register",
    meta: registergNiSicKKBaMeta || {},
    alias: registergNiSicKKBaMeta?.alias || [],
    redirect: registergNiSicKKBaMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91seriesId_93ABPMH7GJjlMeta?.name ?? "reputation-seriesId",
    path: _91seriesId_93ABPMH7GJjlMeta?.path ?? "/reputation/:seriesId()",
    meta: _91seriesId_93ABPMH7GJjlMeta || {},
    alias: _91seriesId_93ABPMH7GJjlMeta?.alias || [],
    redirect: _91seriesId_93ABPMH7GJjlMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/reputation/[seriesId].vue").then(m => m.default || m)
  },
  {
    name: index7QOSw25r7BMeta?.name ?? "reputation",
    path: index7QOSw25r7BMeta?.path ?? "/reputation",
    meta: index7QOSw25r7BMeta || {},
    alias: index7QOSw25r7BMeta?.alias || [],
    redirect: index7QOSw25r7BMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/reputation/index.vue").then(m => m.default || m)
  },
  {
    name: indexHNfPpQBJG4Meta?.name ?? "search",
    path: indexHNfPpQBJG4Meta?.path ?? "/search",
    meta: indexHNfPpQBJG4Meta || {},
    alias: indexHNfPpQBJG4Meta?.alias || [],
    redirect: indexHNfPpQBJG4Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m7leOWvVlBMeta?.name ?? "topic-id",
    path: _91id_93m7leOWvVlBMeta?.path ?? "/topic/:id()",
    meta: _91id_93m7leOWvVlBMeta || {},
    alias: _91id_93m7leOWvVlBMeta?.alias || [],
    redirect: _91id_93m7leOWvVlBMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/topic/[id].vue").then(m => m.default || m)
  },
  {
    name: addressManageXHH3PcsOZiMeta?.name ?? "user-addressManage",
    path: addressManageXHH3PcsOZiMeta?.path ?? "/user/addressManage",
    meta: addressManageXHH3PcsOZiMeta || {},
    alias: addressManageXHH3PcsOZiMeta?.alias || [],
    redirect: addressManageXHH3PcsOZiMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/addressManage.vue").then(m => m.default || m)
  },
  {
    name: bindAccountpUJoR2K45jMeta?.name ?? "user-bindAccount",
    path: bindAccountpUJoR2K45jMeta?.path ?? "/user/bindAccount",
    meta: bindAccountpUJoR2K45jMeta || {},
    alias: bindAccountpUJoR2K45jMeta?.alias || [],
    redirect: bindAccountpUJoR2K45jMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/bindAccount.vue").then(m => m.default || m)
  },
  {
    name: editkmmTazGtgoMeta?.name ?? "user-car-edit",
    path: editkmmTazGtgoMeta?.path ?? "/user/car/edit",
    meta: editkmmTazGtgoMeta || {},
    alias: editkmmTazGtgoMeta?.alias || [],
    redirect: editkmmTazGtgoMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/car/edit.vue").then(m => m.default || m)
  },
  {
    name: indexuWuSR6O5u8Meta?.name ?? "user-car",
    path: indexuWuSR6O5u8Meta?.path ?? "/user/car",
    meta: indexuWuSR6O5u8Meta || {},
    alias: indexuWuSR6O5u8Meta?.alias || [],
    redirect: indexuWuSR6O5u8Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/car/index.vue").then(m => m.default || m)
  },
  {
    name: collectionqsEgKVSpgDMeta?.name ?? "user-collection",
    path: collectionqsEgKVSpgDMeta?.path ?? "/user/collection",
    meta: collectionqsEgKVSpgDMeta || {},
    alias: collectionqsEgKVSpgDMeta?.alias || [],
    redirect: collectionqsEgKVSpgDMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/collection.vue").then(m => m.default || m)
  },
  {
    name: commentE8VbO2BdaGMeta?.name ?? "user-comment",
    path: commentE8VbO2BdaGMeta?.path ?? "/user/comment",
    meta: commentE8VbO2BdaGMeta || {},
    alias: commentE8VbO2BdaGMeta?.alias || [],
    redirect: commentE8VbO2BdaGMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/comment.vue").then(m => m.default || m)
  },
  {
    name: _91id_93d7kin93XQdMeta?.name ?? "user-complaint-id",
    path: _91id_93d7kin93XQdMeta?.path ?? "/user/complaint/:id()",
    meta: _91id_93d7kin93XQdMeta || {},
    alias: _91id_93d7kin93XQdMeta?.alias || [],
    redirect: _91id_93d7kin93XQdMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/complaint/[id].vue").then(m => m.default || m)
  },
  {
    name: index6HuF1hgdt2Meta?.name ?? "user-complaint",
    path: index6HuF1hgdt2Meta?.path ?? "/user/complaint",
    meta: index6HuF1hgdt2Meta || {},
    alias: index6HuF1hgdt2Meta?.alias || [],
    redirect: index6HuF1hgdt2Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/complaint/index.vue").then(m => m.default || m)
  },
  {
    name: dynamic5o6jm6v4kxMeta?.name ?? "user-dynamic",
    path: dynamic5o6jm6v4kxMeta?.path ?? "/user/dynamic",
    meta: dynamic5o6jm6v4kxMeta || {},
    alias: dynamic5o6jm6v4kxMeta?.alias || [],
    redirect: dynamic5o6jm6v4kxMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/dynamic.vue").then(m => m.default || m)
  },
  {
    name: exchange_45logwimRyy2R41Meta?.name ?? "user-exchange-log",
    path: exchange_45logwimRyy2R41Meta?.path ?? "/user/exchange-log",
    meta: exchange_45logwimRyy2R41Meta || {},
    alias: exchange_45logwimRyy2R41Meta?.alias || [],
    redirect: exchange_45logwimRyy2R41Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/exchange-log.vue").then(m => m.default || m)
  },
  {
    name: integration_45logbbUM1YjJR6Meta?.name ?? "user-integration-log",
    path: integration_45logbbUM1YjJR6Meta?.path ?? "/user/integration-log",
    meta: integration_45logbbUM1YjJR6Meta || {},
    alias: integration_45logbbUM1YjJR6Meta?.alias || [],
    redirect: integration_45logbbUM1YjJR6Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/integration-log.vue").then(m => m.default || m)
  },
  {
    name: integrationBt5dFt1aWuMeta?.name ?? "user-integration",
    path: integrationBt5dFt1aWuMeta?.path ?? "/user/integration",
    meta: integrationBt5dFt1aWuMeta || {},
    alias: integrationBt5dFt1aWuMeta?.alias || [],
    redirect: integrationBt5dFt1aWuMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/integration.vue").then(m => m.default || m)
  },
  {
    name: modifyPassword7wCkuJn0SeMeta?.name ?? "user-modifyPassword",
    path: modifyPassword7wCkuJn0SeMeta?.path ?? "/user/modifyPassword",
    meta: modifyPassword7wCkuJn0SeMeta || {},
    alias: modifyPassword7wCkuJn0SeMeta?.alias || [],
    redirect: modifyPassword7wCkuJn0SeMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/modifyPassword.vue").then(m => m.default || m)
  },
  {
    name: modifyPhone2UtuTpfqi6Meta?.name ?? "user-modifyPhone",
    path: modifyPhone2UtuTpfqi6Meta?.path ?? "/user/modifyPhone",
    meta: modifyPhone2UtuTpfqi6Meta || {},
    alias: modifyPhone2UtuTpfqi6Meta?.alias || [],
    redirect: modifyPhone2UtuTpfqi6Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/modifyPhone.vue").then(m => m.default || m)
  },
  {
    name: notificatione0p0EZ4cXOMeta?.name ?? "user-notification",
    path: notificatione0p0EZ4cXOMeta?.path ?? "/user/notification",
    meta: notificatione0p0EZ4cXOMeta || {},
    alias: notificatione0p0EZ4cXOMeta?.alias || [],
    redirect: notificatione0p0EZ4cXOMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/notification.vue").then(m => m.default || m)
  },
  {
    name: store7Q0k0lsgn9Meta?.name ?? "user-store",
    path: store7Q0k0lsgn9Meta?.path ?? "/user/store",
    meta: store7Q0k0lsgn9Meta || {},
    alias: store7Q0k0lsgn9Meta?.alias || [],
    redirect: store7Q0k0lsgn9Meta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/store.vue").then(m => m.default || m)
  },
  {
    name: userInfoQUjwuvU0zlMeta?.name ?? "user-userInfo",
    path: userInfoQUjwuvU0zlMeta?.path ?? "/user/userInfo",
    meta: userInfoQUjwuvU0zlMeta || {},
    alias: userInfoQUjwuvU0zlMeta?.alias || [],
    redirect: userInfoQUjwuvU0zlMeta?.redirect,
    component: () => import("D:/word/qctsw-vue/packages/app/src/pages/user/userInfo.vue").then(m => m.default || m)
  }
]