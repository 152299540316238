import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'
import type { CAR_INSURANCE_CODE, SEX } from '~/shared/constants'
import type { TimeString } from '~/shared/types'

function generatePersist() {
  const persist = [
    {
      storage: persistedState.localStorage,
      paths: ['formData'],
    },
  ]
  return persist
}

export interface PublishFormData {
  isAccept: boolean
  isGetDefaultDriving: boolean
  // base
  collectiveId: number | undefined
  refComplainId: number | undefined
  refComplainContent: string | undefined
  // user
  userName: string
  sex: 0 | typeof SEX[number]['value']
  phone: string
  email: string
  // car
  carBrandName: string | undefined
  carBrandId: number | undefined
  carBrandVendorId: number | undefined
  carVendorName: string | undefined
  carVendorId: number | undefined
  carSeriesName: string | undefined
  carSeriesId: number | undefined
  carModelName: string | undefined
  carModelId: number | undefined
  firstFaultTime: TimeString | undefined
  carInsuranceCode: typeof CAR_INSURANCE_CODE[number]['value'] | undefined
  carTravelKm: string | undefined
  carDealerAreaIds: [string | undefined, string | undefined] // [provinceId, cityId]
  carDealerId: number | undefined // 经销商id
  carDealerName: string | undefined // carDealerName
  carDealerLinkman: string | undefined // carDealerLinkman
  carDealerPhone: string | undefined // carDealerPhone
  carVin: string | undefined
  carEngineNumber: string | undefined
  carRegionPlate: string | undefined
  carNumberPlate: string | undefined
  carShoppingTime: TimeString | undefined
  pictures: string[]
  videos: string[]
  audios: string[]
  quality: string[] // 质量问题 issueId-childIssueId<?-partId-partName> => 1-2-xx
  sale: number[] // 销售问题
  afterSale: number[] // 售后问题
  appeal: number[] // 申诉问题
  title: string
  content: string
  complainMoney: number | null
  isSite?: boolean
  isWillingToLive?: boolean
}

const defaultFormData: PublishFormData = {
  isAccept: false, // 是否同意协议
  isGetDefaultDriving: false, // 是否获取默认车辆信息
  collectiveId: undefined,
  refComplainId: undefined,
  refComplainContent: undefined,
  complainMoney: null,
  userName: '',
  sex: 0,
  phone: '',
  email: '',
  carBrandName: undefined,
  carBrandId: undefined,
  carBrandVendorId: undefined,
  carVendorName: undefined,
  carVendorId: undefined,
  carSeriesName: undefined,
  carSeriesId: undefined,
  carModelName: undefined,
  carModelId: undefined,
  firstFaultTime: undefined,
  carInsuranceCode: undefined,
  carTravelKm: undefined,
  carDealerAreaIds: [undefined, undefined], // [provinceId, cityId]
  carDealerId: undefined, // 经销商id
  carDealerName: undefined, // 经销商名称
  carDealerLinkman: undefined, // 经销商联系人
  carDealerPhone: undefined, // 经销商联系电话
  carVin: undefined, // 车架号
  carEngineNumber: undefined, // 发动机号
  carRegionPlate: undefined, // 车牌号省份
  carNumberPlate: undefined, // 车牌号
  carShoppingTime: undefined, // 购车时间
  pictures: [],
  videos: [],
  audios: [],
  quality: [],
  sale: [],
  afterSale: [],
  appeal: [],
  title: '',
  content: '',
  isSite: false,
  isWillingToLive: undefined,

} as const
export const usePublishStore = defineStore('WEB_PUBLISH', () => {
  const formData = ref<PublishFormData>(cloneDeep(defaultFormData))

  const drivingInfo = ref<Record<string, any>>({})

  function reset() {
    formData.value = cloneDeep(defaultFormData)
    drivingInfo.value = {}
  }
  return {
    formData,
    drivingInfo,
    reset,
  }
}, {
  persist: generatePersist(),
})
